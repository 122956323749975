<template>
  <div class="qingwu">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>系统</el-breadcrumb-item>
          <el-breadcrumb-item>区域设置</el-breadcrumb-item>
          <el-breadcrumb-item>商圈管理</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>

    <div class="admin_main_block admin_m15">
      <div class="header-form">
        <div class="admin_main_block_left">
          <el-form>
            <el-form-item label="运营区域">
              <el-select
                v-model="where.parentCode"
                placeholder="请选择"
                filterable
                clearable
              >
                <el-option label="全部" value=""></el-option>
                <el-option
                  v-for="rs in openAreaList"
                  :key="rs.code"
                  :label="rs.name"
                  :value="rs.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" plain icon="el-icon-search" @click="listData()"
                >条件筛选</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div class="admin_main_block_right">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-button
                type="primary"
                @click="showDailog({})"
                icon="el-icon-plus"
                >新增</el-button
              >
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="admin_table_main">
        <el-table
          :data="list"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.1)"
          @selection-change="handleSelectionChange"
        >
          <el-table-column prop="id" label="ID"></el-table-column>
          <el-table-column prop="name" label="商圈名称"></el-table-column>
          <el-table-column prop="areaName" label="所在区域"></el-table-column>
          <el-table-column prop="cityName" label="所属城市"></el-table-column>
          <el-table-column
            prop="sort"
            label="排序"
            :render-header="renderHeader"
          >
            <template slot-scope="scope">
              <el-input-number
                size="mini"
                placeholder="请输入排序"
                v-model="list[scope.$index].sort"
                @change="setState(scope.row)"
              ></el-input-number>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" width="100px">
            <template slot-scope="scope">
              <el-button
                type="danger"
                class="el-icon-delete"
                @click="deleteBtn(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <div class="admin_table_main_pagination">
          <el-pagination
            @current-change="currentChange"
            @size-change="handleSizeChange"
            background
            :total="totalData"
            :page-size="pageSize"
            :current-page="currentPage"
            :page-sizes="[10, 20, 50, 100]"
            layout="sizes, prev, pager, next, total, jumper"
          ></el-pagination>
        </div>
      </div>
      <edit :row="row" @dialog="getDialog" v-if="dialogEditVisible"></edit>
    </div>
  </div>
</template>
<script>
import edit from "@/components/admin/area/business/edit.vue";
export default {
  components: {
    edit,
  },
  props: {},
  data() {
    return {
      list: [],
      date: "",
      row: {},
      loading: false,
      dialogEditVisible: false,
      openAreaList: "",
      totalData: 0, // 总条数
      pageSize: 20,
      currentPage: 0,
      rolesData: [],
      selectId: "",
      where: {
        page: 0,
        limit: 0,
        parentCode: "",
      },
    };
  },
  methods: {
    listData() {
      this.loading = true;
      this.where.page = this.currentPage;
      this.where.limit = this.pageSize;
      this.$post(this.$api.businessCircleList, this.where).then((res) => {
        this.totalData = res.data.total;
        this.list = res.data.records;
        this.loading = false;
      });
    },
    handleSelectionChange(e) {
      let ids = [];
      e.forEach((v) => {
        ids.push(v.id);
      });
      this.selectId = ids.join(",");
    },
    currentChange(e) {
      this.currentPage = e;
      this.listData();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.listData();
    },
    orderDate(obj) {
      if (obj) {
        this.where.startDate = obj[0];
        this.where.endDate = obj[1];
      }
    },
    getDialog(val) {
      this.dialogEditVisible = val;
    },
    showDailog(data) {
      this.row = data;
      this.dialogEditVisible = true;
    },
    deleteBtn(data) {
      this.$confirm("是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.post(this.$api.businessCircleDel, { id: data.id });
          this.listData();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    setState(data) {
      this.post(this.$api.businessCircleUpdate, {
        id: data.id,
        sort: data.sort,
      });
    },
    post(url, data) {
      this.$postFormData(url, data).then((res) => {
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: "成功!",
          });
          this.listData();
        } else {
          this.$message({
            type: "error",
            message: "失败!",
          });
        }
      });
    },
    // render 事件
    renderHeader(h, { column }) {
      let text = "数字越小 排序越高";
      return h("div", [
        h("span", column.label),
        h("el-tooltip", [
          h("i", {
            class: "el-icon-question",
            style: "color:#409eff;margin-left:5px;",
          }),
          h(
            "div",
            {
              slot: "content",
              // style:"width: 200px;"
            },
            text
          ),
        ]),
      ]);
    },
    getOpenAreaList() {
      const postData = {
        isOpen: 1,
        type: 2,
        areaCode: "",
      };
      this.$postFormData(this.$api.districtOpenAreaList, postData).then(
        (res) => {
          if (res.code == 1) {
            this.openAreaList = res.data;
          }
        }
      );
    },
  },
  created() {
    this.listData();
    this.getOpenAreaList();
  },
};
</script>

